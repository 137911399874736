<template>
    <div>
        <div class="user-bg">
            <img src="../../assets/图标-白-头像@2x.png" alt="">

            <div class="bottom-desc">
                <div class="desc-name"><span style="font-size: 14px;color: rgba(255,255,255,0.5);">指派人：</span>{{debugUser.userName}}</div>
            </div>
        </div>
        
        <div>
            <device-info v-for="(item, i) in list" :item="item" :key="i" :canSelect="true" @rowClick="onRowClick(item)"></device-info>
        </div>
        <div style="margin: .625rem .9375rem;">
            <div style="text-align: left; color: #595959; font-size: 17px;font-weight:bold;">备注</div>
            <van-field type="textarea" v-model="remark" placeholder="输入备注信息..."></van-field>
        </div>
        <div class="btn1" @click="send">确认指派</div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {Toast} from 'vant'
import deviceInfo from '@/components/DeviceInfo'
import {getWaitingDebugEquip, sendEquipWorkMsg} from '@/axios/device'
export default {
    components: {deviceInfo},
    computed: {
        ...mapGetters(['equipInfo', 'userInfo'])
    },
    data(){
        return {
            debugUser: {
                userName: this.$route.query.userName,
                userAccount: this.$route.query.userAccount
            },
            list: [],
            remark: ""
        }
    },
    created(){
        getWaitingDebugEquip({
            companyId: this.equipInfo.companyId,
            equipType: this.equipInfo.equipType,
            pageNo: 1,
            pageSize: 50
        }).then(res => {
            res.content.list.forEach(_itm => {
                _itm._isSelected = false
            });
            this.list = res.content.list
        })
    },
    methods: {
        onRowClick(_item){
            let _r = !_item._isSelected
            if(_r){
                this.list.forEach(_itm => {
                    _itm._isSelected = false
                });
            }
            _item._isSelected = _r
        },
        send(){
            let _find = this.list.find(_itm => _itm._isSelected)
            if(!_find){
                Toast("请选择调试设备！")
            }
            sendEquipWorkMsg({
                createUserId: this.userInfo.loginCode,
                debugUserId: this.debugUser.userAccount,
                equipCode: _find.equipCode,
                remark: this.remark
            }).then(res => {
                this.$router.push({path: "/home/workerRealtime"})
            })
        }
    }
}
</script>


<style lang="scss" scoped>
    .user-bg{
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../../assets/背景图@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        img{
            width: 20px;
        }
        .bottom-desc{
            position: absolute;
            top: 50%;
            margin-top: 30px;
            left: 0;
            right: 0;
            .desc-name{
                color: #fff;
                font-size: 17px;
                margin-bottom: 5px;
            }
            .desc-grp{
                color: rgba(255,255,255,0.5);
                font-size: 14px;
            }
        }
    }
    .btn1{
        width: 295px;
        background: linear-gradient(135deg, #73b9fc 0%, #3f83f8 100%); 
        border-radius: 10px;
        color: #fff;
        padding: 10px 0;
        margin: 10px auto;
    }
</style>